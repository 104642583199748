/* eslint-disable no-unused-vars */
import React from "react";
import { useScreenTitle } from "@whitecobalt/tungsten/esm/common/hooks/useScreenTitle";
import { Link } from "react-router-dom";
import { useAuthAPI } from "@whitecobalt/tungsten/esm/common/hooks/useAuthAPI";
import { useQuery } from "@apollo/client";
import Facade from "@whitecobalt/tungsten/esm/components/Facade";
import Button from "@whitecobalt/tungsten/esm/components/Button";
import Card from "react-bootstrap/Card";
import branding from "@config/branding";
import { TermConditionsGridQuery, TermConditionType } from "@services/query/grid/TermConditionsQuery";
import { useGlobalStoreSelector } from "@services/store/global";
import { useOrganisation } from "@whitecobalt/tungsten/esm/common/hooks/useOrganisation";
import { alphasignLogo } from "@utils/customVariables";
import MarkdownEditor from "@uiw/react-markdown-editor";

interface TermsAndConditionConfirmProps {
    viewVersion?: number;
    applicationAreaID?: number;
}

const TermsAndConditionConfirm: React.FunctionComponent<TermsAndConditionConfirmProps> = ({ viewVersion, applicationAreaID = branding.applicationAreaID }) => {
    useScreenTitle(`Terms and Conditions - ${branding.brandName}`)
    const { data: organisation, user } = useOrganisation()
    const [, setMustSignTerms] = useGlobalStoreSelector<boolean>('mustSignTerms')
    const getTerms = useQuery<{ grid: {items: TermConditionType[] }}>(TermConditionsGridQuery, {
        fetchPolicy: 'no-cache',
        variables: {
            where: {
                UseThisVersion: !viewVersion ? {
                    equals: true
                } : undefined,
                Version: viewVersion && {
                    equals: viewVersion
                },
                ApplicationAreaID: {
                    equals: applicationAreaID
                }
            }
        },
        onCompleted: (response) => {
            if(response.grid.items.length === 0) {
                setMustSignTerms(false)
            }
        }
    })

    const latestTerms = (getTerms.data?.grid.items || [])[0]

    const acceptTerms = useAuthAPI('/api/TermCondition/accept')
    const handler = async () => {
        const payload = {
            original: {
                id: 0
            },
            new: {
                id: 0,
                userID: user?.id,
                termConditionID: latestTerms?.ID
            }
        }

        try {
            const { data: response } = await acceptTerms.call({
                data: payload
            })

            if(response.success) {
                setMustSignTerms(false)
            }
        } catch(error) {
            console.error(error)
        }

    };

    if(viewVersion) {
        return (
            <div data-color-mode="light">
                <Facade loading={getTerms.loading} >
                    <MarkdownEditor.Markdown source={latestTerms?.Description || ''} />
                </Facade>
            </div>
        )
    }

    return (
        <Card className="mx-auto w-100 mt-20" data-color-mode="light" style={{ maxWidth: '80vw', height: '90vh' }}>
            <Card.Header>
                <div className="text-center mb-4" data-env-impose="true">
                    <img src={organisation?.alphasign ? alphasignLogo : branding.logo.splash} style={{maxWidth: 250}}/>
                </div>
                <Card.Title as="h1" className="text-center mb-3">
                    Terms & Conditions
                </Card.Title>
                <p className="text-center text-bold mb-0">
                    Our Terms & Conditions have been updated. please review the details below and click "I Accept" to proceed.
                </p>
            </Card.Header>
            <Card.Body className="overflow-auto pt-0">
                <Facade loading={getTerms.loading} >
                    <MarkdownEditor.Markdown source={latestTerms?.Description || ''} />
                </Facade>
            </Card.Body>
            <Card.Footer className="d-flex justify-content-center align-items-center">
                <Button
                    type="submit"
                    className="font-weight-bold"
                    onClick={handler}  
                    size="lg"
                    disabled={getTerms.loading || acceptTerms.loading}
                    loading={acceptTerms.loading}  
                >
                    I Accept
                </Button>
                <Link
                    to="/auth/logout"
                    className="text-dark-50 text-hover-primary ml-20"
                >
                    I Do not Accept
                </Link>
            </Card.Footer>
        </Card>
    );
}


export default TermsAndConditionConfirm
