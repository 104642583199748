import branding from "@config/branding";
import { useGlobalStoreSelector } from "@services/store/global";
import { useAuthAPI } from "@whitecobalt/tungsten/esm/common/hooks/useAuthAPI";
import { useSession } from "@whitecobalt/tungsten/esm/components/Session";
import { useEffect, useRef } from "react";
import { useLocation } from "react-router-dom";

export const useTermsMustSign = () => {
    const location = useLocation();
    const [{user}] = useSession()
    const [mustSignTerms, setMustSignTerms] = useGlobalStoreSelector<boolean>('mustSignTerms');
    const request = useAuthAPI<boolean>('/api/TermCondition/mustsign');
    const triggerOnce = useRef<boolean>(false)
    
    useEffect(() => {
        if(user) {
            if(triggerOnce.current) return;
            triggerOnce.current = true
            request.call({
                params: {
                    ApplicationAreaID: user?.organisations[0]?.alphasign ? 2 : 1
                }
            }).then((response) => setMustSignTerms(response.data));
        } else {
            triggerOnce.current = false
        }
    }, [user]);

    return {
        loading: request.loading,
        mustSignTerms: mustSignTerms && location.pathname.startsWith('/auth') === false
    }
}