import { gql } from "@apollo/client";

export type TermConditionType = {
    ID: number;
    Description: string;
    Version: number;
    UseThisVersion: boolean;
}

export const TermConditionsGridQuery = gql `
    query TermConditionsGridQuery(
        $skip: Int,
        $take: Int,
        $where: TermConditionFilterInput,
        $order: [TermConditionSortInput!],
    ) {
        grid: TermConditions(
            skip: $skip,
            take: $take,
            where: $where,
            order: $order,
        ) {
            totalCount
            items {
                ID
                Description
                Version
                UseThisVersion
            }
        }
    }
`

export const TermConditionScreenQuery = gql`
    query(
        $id: Int!
    ) {
        screen: TermCondition(
            iD: $id
        ) {
            ID
            Description
            Version
            UseThisVersion
            UserIDs
        }
    }
`